import { GitBranchPlus } from "lucide-react"
import { Form } from "react-router"
import { TextareaAutosize } from "~/components/textarea-autosize"
import { Button } from "~/components/ui/button"
import { Card } from "~/components/ui/card"
import { SelectStyle } from "./selectStyle"

export default function NewFlow() {
  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-2xl font-bold flex gap-1 items-center">
        <GitBranchPlus /> New flow
      </h1>
      <p className="mb-2">A flow is a reusable template for a chat.</p>

      <Card className="max-w-lg p-2">
        <Form method="post">
          <fieldset className="flex flex-col gap-2">
            <div className="flex items-center">
              <TextareaAutosize
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey && "form" in e.target) {
                    e.preventDefault()
                    ;(e.target.form as HTMLFormElement)?.requestSubmit()
                  }
                }}
                name="userMessage"
                placeholder="Instructions"
                className="flex-1 border-none shadow-none outline-hidden overflow-hidden min-h-9 h-9"
              />
            </div>
            <div className="flex gap-2 justify-between">
              <SelectStyle onChange={() => {}} />
              <Button type="submit">Create flow</Button>
            </div>
          </fieldset>
        </Form>
      </Card>
    </div>
  )
}
